.landing {
    display: flex;
    width: 100%;
    height: calc(100vh - var(--height-header) );
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .content {
        display: flex;
        width: 100%;
        max-width: var(--max-width);
        align-items: center;
        justify-content: center;

        .presentation {
            display: flex;
            align-items: center;
            color: var(--white);
            width: 100%;


            .social {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                z-index: 10;
                width: 400px;


                @media screen and (max-width: 768px) {
                    width: 100%;
                    max-width: 100%;
                    position: absolute;
                    align-items: flex-start;
                    margin-left: 32px;
                }

                .socialWrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;
                    max-width: 400px;


                    @media screen and (max-width: 1200px) {
                        max-width: 100%;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: flex-start;
                    }

                    @media screen and (max-width: 768px) {
                        margin-left: 0px;
                    }
                }

                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0.5rem;
                    transition: all 0.2s ease-in-out;
                    font-size: 3rem;
                    color: var(--white);
                    border: 2px solid var(--white);
                    border-radius: 16%;
                    padding: 1rem;

                    @media screen and (max-width: 1200px) {
                        padding: 0.5rem;
                    }

                    &:hover {
                        transform: scale(1.2);
                    }

                    img {
                        width: 3rem;
                        height: 3rem;
                    }
                }
            }

            .imageWrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                z-index: 2;
                max-width: 580px;
                width: 100%;
                height: 100%;

                img {
                    position: relative;

                    @media screen and (max-width: 768px) {
                        // position: absolute !important;
                        width: 100%;
                        max-width: 100%;
                        object-fit: cover !important;
                        height: 100%;
                        // bottom: 0;
                    }
                }
            }
        }
    }

    .background {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 0;
        opacity: 0.2;
        filter: blur(2px);
        -webkit-mask-image: linear-gradient(
            to right,
            transparent 5%,
            rgba(0, 0, 0, 1) 75%
        );
        mask-image: linear-gradient(
            to right,
            transparent 5%,
            rgba(0, 0, 0, 1) 75%
        );
    }
}
